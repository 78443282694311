import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";
import { globalHistory } from "@reach/router";
import { myContentInfo } from "../../my-content";

const Head = ({ title, description }) => {
  return (
    <>
      <Helmet title={`${myContentInfo.websiteTitle} | ${title}`}>
        {/* . */}
        <html lang="en" />

        <meta
          name="description"
          content={description ? description : myContentInfo.metaTagDescription}
          data-react-helmet="true"
        ></meta>

        <link
          rel="canonical"
          href={globalHistory.location.href ? globalHistory.location.href : ``}
        ></link>

        <link rel="preconnect" href="//fonts.googleapis.com"></link>
        <link rel="preconnect" href="//fonts.gstatic.com" crossorigin></link>

        <link
          href="//fonts.googleapis.com/css2?family=Raleway:wght@300;500;600;700&family=Sora:wght@300;400;500;600"
          rel="stylesheet"
        ></link>

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LYRDPCJ4FK"
        ></script> */}
        <script
          async=""
          src="https://www.googletagmanager.com/gtag/js?id=G-K2CQCHN0GM"
        ></script>

        {/*  +  */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"
          integrity="sha512-1sCRPdkRXhBV2PBLUdRb4tMg1w2YPf37qatUFeS7zlBy7jJI8Lf4VHwWfZZfpXtYSLy85pkm9GaYVYMfw5BC1A=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        {/* 
        <meta
          name="ahrefs-site-verification"
          content="0a97729e828f54b91ffa8b017116dbe0955ac098224d0f697d2bfa50263cdbf7"
        ></meta> */}
      </Helmet>
    </>
  );
};

export default Head;
