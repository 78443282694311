import React from "react";
import { Link } from "gatsby";
import { myContentInfo } from "../../my-content";

export default function Layout({ children }) {
  return (
    <>
      {/* <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); gtag('config', 'G-LYRDPCJ4FK');`,
        }}
      /> */}
      <div class="the-video-element">
        <video autoplay="autoplay" loop="loop" muted="muted">
          <source src="/blurhighway-sm.mp4" type="video/mp4"></source>
        </video>
      </div>
      <div className="primary-wrap">
        {/* need wrapper to push footer flush  */}
        <div className="i-am-body-wrap i-am-responsive-container">
          <div className="navigation-bar">
            <div className="left">
              <div className="logo">
                <Link to="/">
                  <span>
                    <img src="/logo.png" alt={myContentInfo.websiteTitle} />
                  </span>
                  <strong>{myContentInfo.websiteTitle}</strong>
                </Link>
              </div>
            </div>
            {/* <div className="right"></div> */}
          </div>

          <div>
            <div className="app-wrapper">{children}</div>
          </div>
        </div>

        <div className="footer">
          <div className="footer-inside i-am-responsive-container">
            <div className="copyright">
              dro·mo·ma·nia - an uncontrollable impulse to wander
            </div>
            <div className="copyright">
              Copyright {new Date().getFullYear()} Dromogo
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
