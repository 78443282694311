/**
 * search and replace name
 * logo
 * url
 * favicon
 * google analytics
 *
 */

// https://www.flaticon.com/search?word=logo

module.exports = {
  myContentInfo: {
    shortName: `dromogo`,
    siteUrl: `https://www.dromogo.com`,
    websiteTitle: `DromoGo Itineraries`,
    metaTagDescription: `dromogo is Fixing air conditioners `,
  },
};
