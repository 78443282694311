import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";

import { myContentInfo } from "../../my-content";

const IndexPage = () => {
  return (
    <>
      <Head title={`Welcome to ${myContentInfo.websiteTitle}`} description="" />
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main">
                  <div className="aboutit">
                    <h2>Itineraries to brag about! </h2>
                    <p>
                      Are you looking for cool trip ideas where you can
                      experience new and memorable things? Are you looking to
                      take a vacation that will make your friends and family
                      jealous? Or maybe you're just wanting to take a trip that
                      features different activities than where the crowds of
                      tourists are going?{" "}
                    </p>
                    <p>
                      We here at DromoGo want to help you. Through personal
                      travels of our own, we've learned just how rewarding
                      outside the box trips can be and we want to help you
                      experience that same feeling! Our goal is to take the hard
                      work out of creative travel and to help you vacation in
                      new and exciting ways. We can do that by providing you
                      well planned itineraries that cover it all.
                    </p>
                    <p>
                      We provide you details for things to do, places to stay,
                      and even where to eat. Everything you need to easily take
                      trips you can brag about! We've got some great itineraries
                      already on the site today and new ones coming all the
                      time.
                    </p>
                  </div>

                  {/* <p>
                    lorum pisum lorum pisum lorum pisum lorum pisum lorum pisum
                    lorum pisum lorum pisum lorum pisum lorum pisum lorum pisum
                    lorum pisum lorum pisum lorum pisum lorum pisum lorum pisum
                    lorum pisum lorum pisum lorum pisum lorum pisum lorum pisum
                    lorum pisum lorum pisum <a>lorum pisum </a> lorum pisum
                    lorum pisum
                  </p> */}
                </div>
                <div className="right">
                  <div className="top">
                    We work hard to partner with businesses and services that we
                    trust. We look for great experience with companies that
                    provide a quality product and a consistent experience.
                  </div>
                  <div>
                    <h4>Hotels</h4>
                    <div class="section-links">
                      <div>
                        <a
                          href="https://prf.hn/click/camref:1101lkU2G"
                          target="_blank"
                        >
                          {" "}
                          Marriott{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=1.41650.1000000070&amp;trid=1257695.177965&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          {" "}
                          Hilton{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=2.3070943.10810340&amp;trid=1257695.158333&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          {" "}
                          Radisson{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=2.1675692.12574664&amp;trid=1257695.156959&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          IHG{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>Flights</h4>
                    <div class="section-links">
                      <div>
                        <a href="https://www.delta.com/" target="_blank">
                          {" "}
                          Delta{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a href="https://www.united.com/en/us" target="_blank">
                          {" "}
                          United{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://www.aa.com/homePage.do"
                          target="_blank"
                        >
                          {" "}
                          American{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a href="https://www.spirit.com/" target="_blank">
                          {" "}
                          Spirit{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>Short Term Rentals</h4>
                    <div class="section-links">
                      <div>
                        <a
                          href="https://prf.hn/click/camref:1101loRYI"
                          target="_blank"
                        >
                          VRBO
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a href="https://www.airbnb.com/" target="_blank">
                          {" "}
                          AirBnB{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>Rental Cars</h4>

                    <div class="section-links">
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=156074.4723.988265&amp;trid=1257695.192325&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          {" "}
                          National{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=2.3739701.14506104&amp;trid=1257695.158320&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          {" "}
                          Hertz{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=156074.4724.333510&amp;trid=1257695.192304&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          {" "}
                          Alamo{" "}
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>{" "}
                      <div>
                        <a
                          href="https://track.flexlinkspro.com/g.ashx?foid=156074.4720.546122&amp;trid=1257695.189045&amp;foc=16&amp;fot=9999&amp;fos=5"
                          target="_blank"
                        >
                          {" "}
                          Enterprise
                          <i
                            class="fas fa-external-link-alt"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <p>
                    lorum pisum lorum pisum lorum pisum lorum pisum lorum pisum
                    lorum pisum lorum pisum lorum pisum lorum pisum lorum pisum
                    lorum pisum lorum pisum <a>lorum pisum </a> lorum pisum
                    lorum pisum
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
